var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"HAWB","bold":"","noTranslate":""}}),_c('div',{staticClass:"ml-auto"},[_c('TButton',{staticClass:"mr-1",attrs:{"content":"Add HAWB","variant":"outline","icon":"cil-plus"},on:{"click":function($event){_vm.showModalAddHawb = true}}})],1),(_vm.invoice.id)?_c('AddHAWB',{attrs:{"show":_vm.showModalAddHawb,"invoiceId":_vm.invoice.id},on:{"update:show":function($event){_vm.showModalAddHawb=$event},"updated":function($event){return _vm.$emit('updated')}}}):_vm._e()],1),_c('CCardBody',[(_vm.invoice.house_airway_bills.length)?_c('TTableSimple',{attrs:{"items":_vm.invoice.house_airway_bills,"fields":_vm.hawbFields,"noResult":""},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TButtonEdit',{on:{"click":function($event){return _vm.selectHawb(item)}}}),_c('TButtonDelete',{on:{"click":function($event){return _vm.deleleHawb(item.id)}}})],1)])]}},{key:"flight_code",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.flight_code,"noTranslate":""}})],1)]}},{key:"max_volume",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageVolume',{attrs:{"value":item.max_volume}})],1)]}},{key:"max_weight",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.max_weight,"suffix":"kg"}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"dateOnly":""}})],1)]}},{key:"voucher",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[(
                item.info_attachment && _vm.checkMimeType(item.info_attachment)
              )?_c('TButton',{staticClass:"mr-1",attrs:{"icon":"cil-eye","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.selectVoucher(item.path_attachment)}}}):_vm._e(),_c('CLink',{attrs:{"href":_vm.getUrlAttachment(item.path_attachment)}},[(item.path_attachment)?_c('TButton',{attrs:{"color":"info","size":"sm","variant":"outline","icon":"cid-cloud-download"}}):_vm._e()],1)],1)])]}}],null,false,3313212119)}):_c('TMessageNoData'),(_vm.showVoucherReview)?_c('TModal',{attrs:{"size":"","title":"Voucher","show":_vm.showVoucherReview},on:{"update:show":function($event){_vm.showVoucherReview=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span')]},proxy:true}],null,false,2058430185)},[_c('CImg',{staticClass:"p-0 d-flex mx-auto",staticStyle:{"object-fit":"contain"},attrs:{"thumbnail":"","fluidGrow":"","src":_vm.getUrlAttachment(_vm.path_file),"placeholderColor":""}})],1):_vm._e(),_c('EditHAWB',{attrs:{"detail":_vm.hawb_selected,"invoiceId":_vm.invoice.id,"show":_vm.showModalEditHawb,"editable":""},on:{"update:show":function($event){_vm.showModalEditHawb=$event},"updated":function($event){return _vm.$emit('updated')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }